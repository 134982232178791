<template>
    <div>
        <div v-if="sensors.wind_direction <= 22.5">
            <h2 class="primary--text pr-3">N</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 22.5 && sensors.wind_direction <= 45
            "
        >
            <h2 class="primary--text pr-3">NNE</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 45 && sensors.wind_direction <= 67.5
            "
        >
            <h2 class="primary--text pr-3">NE</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 67.5 && sensors.wind_direction <= 90
            "
        >
            <h2 class="primary--text pr-3">ENE</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 90 && sensors.wind_direction <= 112.5
            "
        >
            <h2 class="primary--text pr-3">E</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 112.5 && sensors.wind_direction <= 135
            "
        >
            <h2 class="primary--text pr-3">ESE</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 135 && sensors.wind_direction <= 157.5
            "
        >
            <h2 class="primary--text pr-3">SE</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 157.5 && sensors.wind_direction <= 180
            "
        >
            <h2 class="primary--text pr-3">SSE</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 180 && sensors.wind_direction <= 202.5
            "
        >
            <h2 class="primary--text pr-3">S</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 202.5 && sensors.wind_direction <= 225
            "
        >
            <h2 class="primary--text pr-3">SSW</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 225 && sensors.wind_direction <= 247.5
            "
        >
            <h2 class="primary--text pr-3">SW</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 247.5 && sensors.wind_direction <= 270
            "
        >
            <h2 class="primary--text pr-3">WSW</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 270 && sensors.wind_direction <= 292.5
            "
        >
            <h2 class="primary--text pr-3">W</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 292.5 && sensors.wind_direction <= 315
            "
        >
            <h2 class="primary--text pr-3">WNW</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 315 && sensors.wind_direction <= 337.5
            "
        >
            <h2 class="primary--text pr-3">NW</h2>
        </div>
        <div
            v-else-if="
                sensors.wind_direction > 337.5 && sensors.wind_direction <= 360
            "
        >
            <h2 class="primary--text pr-3">NNW</h2>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"; // Map Actions / Getters
export default {
    name: "wind_direction",
    data: () => ({
        current: [],
        sensors: {
            wind_direction: "-",
        },
        station: "",
    }),
    computed: {
        ...mapGetters({
            GET_weatherDataCurrent: "GET_weatherDataCurrent",
            GET_weatherDataStationById: "GET_weatherDataStationById",
        }),
    },
    watch: {
        GET_weatherDataCurrent: {
            handler() {
                this.current = this.GET_weatherDataCurrent;
                this.updateSensors();
            },
            deep: true,
        },
    },
    methods: {
        getSensor: function(sensor_type) {
            return this.current.find(
                (sensor) =>
                    parseInt(sensor.sensor_type) === parseInt(sensor_type)
            );
        },
        updateSensors() {
            var t = this;
            const sensorId = 28; // t.station.station_name === 'TTW Zone 10' ? 83 : 28;
            t.sensors.wind_direction = parseFloat(
                t.getSensor(sensorId).data[0].wind_dir
            );
        },
    },
    async mounted() {
        let t = this;
        t.station = await t.GET_weatherDataStationById(
            t.$route.params.stationid
        );
    },
};
</script>
