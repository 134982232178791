<template>
    <div
        class="d-flex align-center flex-column justify-start fill-height"
        style="width: 100% !important;"
    >
        <v-container fluid width="100%" class="lightgrey pa-5">
            <v-card flat color="lightgrey" height="100%">
                <v-row class="pt-4 pl-4">
                    <v-col cols="2" md="1" lg="1" class="d-flex align-center">
                        <v-row class="">
                            <v-icon
                                size="40"
                                color="primary"
                                class="align-start pl-2"
                                >icons8-record</v-icon
                            >
                        </v-row>
                    </v-col>
                    <v-col cols="10" class="d-flex align-center">
                        <div class="">
                            <div>
                                <h3 class="font-weight-normal darktext--text">
                                    {{ $t("menu.liveView") }}
                                </h3>
                            </div>
                            <div class="grey--text">
                                <h5>
                                    <span
                                        v-if="JSON.stringify(station) !== '{}'"
                                        >{{ station.station_name }}
                                    </span>
                                </h5>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>

        <v-row class="pt-4 px-1" style="width: 100% !important;">
            <v-col cols="12" xs="12" sm="6" md="5" lg="4">
                <v-btn
                    block
                    depressed
                    large
                    @click="MIX_go('/farming')"
                    class="mb-3 lightgrey"
                    >{{ $t("buttons.back") }}</v-btn
                >

                <dashcard
                    :currentdata="currentData.airTemp"
                    :key="currentData.airTemp.componentKey"
                />
                <dashcard
                    :currentdata="currentData.rainfall"
                    :key="currentData.rainfall.componentKey"
                />
                <v-card
                    flat
                    color="accent"
                    width="100%"
                    height="80"
                    class="mb-4 pa-2"
                >
                    <v-row class="pa-2">
                        <v-col cols="2">
                            <v-icon
                                size="50"
                                position="left"
                                color="primary"
                                class=""
                                >icons8-wind-gauge</v-icon
                            >
                        </v-col>
                        <v-col cols="8" class="d-flex align-center">
                            <div class="font-weight-regular pl-2">
                                {{ $t("weather.windDirection") }}
                                <div class="caption grey--text">
                                    {{
                                        MIX_formatDateTime(
                                            sensors.wind_directionUpdated,
                                            "X",
                                            "DD-MMM-YY HH:mm:ss"
                                        )
                                    }}
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            cols="2"
                            class="d-flex justify-center align-center"
                        >
                            <winddirection />
                        </v-col>
                    </v-row>
                </v-card>
                <!-- <dashcard :currentdata="currentData.windDirection" :key="currentData.windDirection.componentKey" /> -->
                <dashcard
                    :currentdata="currentData.windSpeed"
                    :key="currentData.windSpeed.componentKey"
                />
                <dashcard
                    :currentdata="currentData.soilTemp"
                    :key="currentData.soilTemp.componentKey"
                />
                <dashcard
                    :currentdata="currentData.soilMoisture"
                    :key="currentData.soilMoisture.componentKey"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
// @ is an alias to /src
//
// require('csg-wp-api.node_modules.index.js')
import { mapGetters } from "vuex"; // Map Actions / Getters
export default {
    name: "Live",
    data: () => ({
        station: {},
        current: [],
        sensors: {
            temperature: "-",
            humidity: "-",
            humidityUpdate: "-",
            rainfall: "-",
            wind_direction: "-",
            wind_speed: "-",
            soil_moisture: "-",
            soil_temp: "-",
        },
        currentData: {
            airTemp: {
                title: "weather.airTemperature",
                unit: "°C",
                subtitle: "-",
                icon: "icons8-temperature",
                value: "-",
                status: "primary",
                componentKey: 0,
            },
            rainfall: {
                title: "weather.rainfall",
                unit: "mm/hr",
                subtitle: "-",
                icon: "icons8-hygrometer",
                value: "-",
                status: "primary",
                componentKey: 1,
            },
            windDirection: {
                title: "weather.windDirection",
                subtitle: "-",
                icon: "icons8-wind-gauge",
                value: "-",
                status: "primary",
                componentKey: 2,
            },
            windSpeed: {
                title: "weather.windSpeed",
                unit: "mph",
                subtitle: "-",
                icon: "icons8-windsock",
                value: "-",
                status: "primary",
                componentKey: 3,
            },
            soilTemp: {
                title: "weather.soilTemperature",
                unit: "°C",
                subtitle: "-",
                icon: "icons8-temperature",
                value: "-",
                status: "primary",
                componentKey: 4,
            },
            soilMoisture: {
                title: "weather.soilMoisture",
                unit: "bar",
                subtitle: "-",
                icon: "icons8-soil",
                value: "-",
                status: "primary",
                componentKey: 5,
            },
        },
        date: new Date().toISOString().substr(0, 10),
    }),
    computed: {
        ...mapGetters({
            GET_weatherDataStations: "GET_weatherDataStations",
            GET_weatherDataStationById: "GET_weatherDataStationById",
            GET_weatherDataCatalog: "GET_weatherDataCatalog",
            GET_weatherDataCurrent: "GET_weatherDataCurrent",
            GET_weatherDataCatalogBySensorType:
                "GET_weatherDataCatalogBySensorType",
            GET_weatherDataForecast: "GET_weatherDataForecast",
        }),
    },
    methods: {
        convertToCelcius: function(value) {
            if (!value) return "";
            return parseFloat(((5 / 9) * (parseFloat(value) - 32)).toFixed());
        },
        getSensor: function(sensor_type) {
            return this.current.find(
                (sensor) =>
                    parseInt(sensor.sensor_type) === parseInt(sensor_type)
            );
        },
        updateSensors() {
            var t = this;
            //const sensorId = t.station.station_name === 'TTW Zone 10' ? 83 : 28;
            const sensorId = 28;
            console.log(
                "get sensor = " + JSON.stringify(t.getSensor(sensorId), null, 2)
            );
            t.sensors.humidity = parseFloat(
                t.getSensor(sensorId).data[0].hum_out
            );
            t.sensors.humidityUpdated = t.getSensor(sensorId).data[0].ts;
            t.humidityGaugeKey++;
            var soil_temp = parseFloat(t.getSensor(105).data[0].temp_last);
            t.sensors.soil_temp = t.convertToCelcius(soil_temp);
            var temperature = parseFloat(
                t.getSensor(sensorId).data[0].temp_out
            );
            t.sensors.temperature = t.convertToCelcius(temperature);
            t.sensors.temperatureUpdated = t.getSensor(sensorId).data[0].ts;
            t.temperatureGaugeKey++;
            t.sensors.rainfall = parseFloat(
                t.getSensor(sensorId).data[0].rain_rate_mm
            );
            t.sensors.rainfallUpdated = t.getSensor(sensorId).data[0].ts;
            t.sensors.wind_direction = parseFloat(
                t.getSensor(sensorId).data[0].wind_dir
            );
            t.sensors.wind_directionUpdated = t.getSensor(sensorId).data[0].ts;
            t.sensors.wind_speed = parseFloat(
                t.getSensor(sensorId).data[0].wind_speed
            );
            t.sensors.wind_speedUpdated = t.getSensor(sensorId).data[0].ts;
            t.sensors.soil_moisture = parseFloat(
                t.getSensor(108).data[0].moist_soil_last
            ); //.toFixed()
            t.MIX_progressBar({ show: false });
            // Create all the sensors and store their values
        },
        updateAirTemp() {
            var t = this;
            // const sensorId = t.station.station_name === 'TTW Zone 10' ? 83 : 28;
            const sensorId = 28;
            t.currentData.airTemp.value = t.convertToCelcius(
                parseFloat(t.getSensor(sensorId).data[0].temp_out)
            );
            t.currentData.airTemp.subtitle = t.MIX_formatDateTime(
                t.getSensor(sensorId).data[0].ts,
                "X",
                "DD-MMM-YY HH:mm:ss"
            );
            t.currentData.airTemp.componentKey++;
        },
        updateRainfall() {
            var t = this;
            // const sensorId = t.station.station_name === 'TTW Zone 10' ? 83 : 28;
            const sensorId = 28;
            t.currentData.rainfall.value = parseFloat(
                t.getSensor(sensorId).data[0].rain_rate_mm
            );
            t.currentData.rainfall.subtitle = t.MIX_formatDateTime(
                t.getSensor(sensorId).data[0].ts,
                "X",
                "DD-MMM-YY HH:mm:ss"
            );
            t.currentData.rainfall.componentKey++;
        },
        updateWindDirection() {
            var t = this;
            // /const sensorId = t.station.station_name === 'TTW Zone 10' ? 83 : 28;
            const sensorId = 28;
            t.currentData.windDirection.value = parseFloat(
                t.getSensor(sensorId).data[0].wind_dir
            );
            t.currentData.windDirection.subtitle = t.MIX_formatDateTime(
                t.getSensor(sensorId).data[0].ts,
                "X",
                "DD-MMM-YY HH:mm:ss"
            );
            t.currentData.windDirection.componentKey++;
        },
        updateWindSpeed() {
            var t = this;
            //const sensorId = t.station.station_name === 'TTW Zone 10' ? 83 : 28;
            const sensorId = 28;
            t.currentData.windSpeed.value = parseFloat(
                t.getSensor(sensorId).data[0].wind_speed
            );
            t.currentData.windSpeed.subtitle = t.MIX_formatDateTime(
                t.getSensor(sensorId).data[0].ts,
                "X",
                "DD-MMM-YY HH:mm:ss"
            );
            t.currentData.windSpeed.componentKey++;
        },
        updateSoilTemp() {
            var t = this;
            t.currentData.soilTemp.value = t.convertToCelcius(
                parseFloat(t.getSensor(105).data[0].temp_last)
            );
            t.currentData.soilTemp.subtitle = t.MIX_formatDateTime(
                t.getSensor(105).data[0].ts,
                "X",
                "DD-MMM-YY HH:mm:ss"
            );
            console.log(
                "soil tep = " + JSON.stringify(t.getSensor(105), null, 2)
            );
            t.currentData.soilTemp.componentKey++;
        },
        updateSoilMoisture() {
            var t = this;
            t.currentData.soilMoisture.value = parseFloat(
                t.getSensor(108).data[0].moist_soil_last
            );
            t.currentData.soilMoisture.subtitle = t.MIX_formatDateTime(
                t.getSensor(108).data[0].ts,
                "X",
                "DD-MMM-YY HH:mm:ss"
            );
            t.currentData.soilMoisture.componentKey++;
        },
    },
    async mounted() {
        var t = this;
        t.MIX_progressBar({ show: true });
        // await t.MIX_weatherDataCatalog();
        // await t.MIX_weatherDataStations();
        var routerPath = t.$route.path.split("/");
        var stationId = routerPath[2];
        t.station = await t.GET_weatherDataStationById(stationId);
        await t.MIX_weatherDataForecast(
            t.station.latitude,
            t.station.longitude
        );
        await t.MIX_weatherDataCurrent(stationId);
        t.current = await t.GET_weatherDataCurrent;
        t.updateSensors();
        t.updateAirTemp();
        t.updateRainfall();
        t.updateWindDirection();
        t.updateWindSpeed();
        t.updateSoilTemp();
        t.updateSoilMoisture();
        // console.log("rainfall = " + t.currentData.rainfall.value)
    },
};
</script>
