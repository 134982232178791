var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.sensors.wind_direction <= 22.5)?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("N")])]):(
            _vm.sensors.wind_direction > 22.5 && _vm.sensors.wind_direction <= 45
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("NNE")])]):(
            _vm.sensors.wind_direction > 45 && _vm.sensors.wind_direction <= 67.5
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("NE")])]):(
            _vm.sensors.wind_direction > 67.5 && _vm.sensors.wind_direction <= 90
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("ENE")])]):(
            _vm.sensors.wind_direction > 90 && _vm.sensors.wind_direction <= 112.5
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("E")])]):(
            _vm.sensors.wind_direction > 112.5 && _vm.sensors.wind_direction <= 135
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("ESE")])]):(
            _vm.sensors.wind_direction > 135 && _vm.sensors.wind_direction <= 157.5
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("SE")])]):(
            _vm.sensors.wind_direction > 157.5 && _vm.sensors.wind_direction <= 180
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("SSE")])]):(
            _vm.sensors.wind_direction > 180 && _vm.sensors.wind_direction <= 202.5
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("S")])]):(
            _vm.sensors.wind_direction > 202.5 && _vm.sensors.wind_direction <= 225
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("SSW")])]):(
            _vm.sensors.wind_direction > 225 && _vm.sensors.wind_direction <= 247.5
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("SW")])]):(
            _vm.sensors.wind_direction > 247.5 && _vm.sensors.wind_direction <= 270
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("WSW")])]):(
            _vm.sensors.wind_direction > 270 && _vm.sensors.wind_direction <= 292.5
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("W")])]):(
            _vm.sensors.wind_direction > 292.5 && _vm.sensors.wind_direction <= 315
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("WNW")])]):(
            _vm.sensors.wind_direction > 315 && _vm.sensors.wind_direction <= 337.5
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("NW")])]):(
            _vm.sensors.wind_direction > 337.5 && _vm.sensors.wind_direction <= 360
        )?_c('div',[_c('h2',{staticClass:"primary--text pr-3"},[_vm._v("NNW")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }